import WarningAlert from "../components/warning_alert/WarningAlert";
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer";
import "./MainLayout.css"

const MainLayout = ({children}) => {
    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                minHeight: "100vh",
            }}>
                <div>
                    <Header/>
                    <WarningAlert>Сайт находится на этапе разработки</WarningAlert>
                </div>
                <main style={{
                    flexGrow: 1
                }}>
                    {children}
                </main>
                <div style={{marginBottom: "1rem"}}>
                    <Footer/>
                </div>
            </div>
        </>
    )
}
export default MainLayout;