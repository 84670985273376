const Services = function(){
    return (
    <>
        <section className="default-section">
            <p style={{paddingTop:"10px"}} className="piazzolla-200">На этапе разработки</p>
        </section>
    </>
    )
}


export default Services