import MainLayout from '../../layouts/MainLayout';
import Main from '../../pages/main/Main';
import { BrowserRouter, Route, Routes } from 'react-router';
import './App.css';
import { APP_ROUTE } from '../../const';
import Services from '../../pages/services/Services';
import About from '../../pages/about/About';

function App() {
  return (
    <BrowserRouter>
      <MainLayout>
        <Routes>
            <Route path="/" element={<Main/>}/>
            <Route path={APP_ROUTE.main} element={<Main/>}/>
            <Route path={APP_ROUTE.services} element={<Services/>}></Route>
            <Route path={APP_ROUTE.blog} element={<Services/>}></Route>
            <Route path={APP_ROUTE.about} element={<About/>}></Route>
            {/* <Route path="*" element={<Responses404 actions={<Button label="Назад" onClick={() => window.history.back()}/>}/>}/> TODO */}
          </Routes>
      </MainLayout>
    </BrowserRouter>
  );
}

export default App;
