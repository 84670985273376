import "./Main.css"

const Main = function(){
    return(
        <>
            <section className="main-container">
                    <div className='description-container'>
                        <img className='description-image'  src='./spb-gate.png'/>
                        <p className='description-text piazzolla-200'>
                            Свою историю компаний «Эланс» начала в 2024 году в рамках программы декоративно-прикладных ремесел для реконструкции,
                            ремонта, изготовления малых архитектурных форм, дверей и ворот в историческом центре Санкт-Петербурга.
                            Наша цель — развивать ремесленное дело, объединить мастеров ковки и столяров,
                            с помощью новых технологий воссоздать исторический облик архитектурного искусства.
                        </p>
                    </div>
            </section>
            {/* <div width='120px' height='1000px' style={{position:'relative', display:'block'}}>
                <img src='./spb-background.png' style={{position:"absolute",width:'100%', height:'auto'}}/>
            </div> */}
        </>
    )
}

export default Main