import "./Header.css"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router";
import { APP_ROUTE } from "../../const";

const Header = function(){
    const navigate = useNavigate()

    return(
        <>
            <Navbar expand="lg" className="bg-body-tertiary paz piazzolla-200">
                <Container>
                    <Navbar.Brand href="#home" onClick={() => navigate(APP_ROUTE.main)}>
                        <img src='logo.svg' className="logo"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <p className="contact"><img height="10px" width="auto" src="/icons/email.svg"/>elans-spb@yandex.ru</p>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="nav-bar">
                            <Nav.Item>
                                <Nav.Link onClick={() => navigate(APP_ROUTE.blog)}>Блог</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => navigate(APP_ROUTE.services)}>Услуги</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => navigate(APP_ROUTE.about)}>О нас</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header