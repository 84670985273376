import Nav from 'react-bootstrap/Nav';

const Footer = function(){
    return (<>
        <footer>
            <div style={{display:"flex"}} className='piazzolla-200'>
                <Nav style={{flex:1}}>
                    <Nav.Link>Главная страница</Nav.Link>
                    <Nav.Link>Услуги компании</Nav.Link>
                </Nav>
                <p style={{width:'200px',margin:'auto'}}>© {new Date().getFullYear()} Эланс</p>
            </div>
        </footer>
    </>);
}

export default Footer